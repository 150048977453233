var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        filterOption: _vm.filterOption,
        "option-filter-prop": "children",
        "show-search": "",
        "allow-clear": "",
        loading: _vm.merkOption.fetching,
        disabled: _vm.disabled,
        value: _vm.value
      },
      on: {
        search: _vm.searchMerk,
        input: _vm.handleChange,
        change: _vm.handleChange
      },
      scopedSlots: _vm._u([
        {
          key: "dropdownRender",
          fn: function(menu) {
            return _c(
              "div",
              {},
              [
                _c("v-nodes", { attrs: { vnodes: menu } }),
                _c("div", { staticClass: "mt-2" }),
                _c(
                  "div",
                  {
                    staticStyle: { padding: "8px", cursor: "pointer" },
                    on: {
                      mousedown: function(e) {
                        return e.preventDefault()
                      },
                      click: _vm.addNew
                    }
                  },
                  [
                    _c("a-icon", { attrs: { type: _vm.iconType } }),
                    _vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")
                  ],
                  1
                )
              ],
              1
            )
          }
        }
      ])
    },
    _vm._l(_vm.merkOption.data, function(item, index) {
      return _c("a-select-option", { key: index, attrs: { value: item } }, [
        _vm._v(" " + _vm._s(item || "-") + " ")
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }