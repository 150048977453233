

































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { productService } from "@/services/product.service";
import { Component, Vue } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
});

/**
 * @deprecated
 * new component {@linkcode MasterProductMerkSelect.vue}
 */
@Component
export default class MerkSelect extends Props {
  merkOption = {
    data: [] as string[],
    search: "",
    fetching: true,
    initialData: [] as string[],
  };
  iconType: "plus" | "loading" = "plus";

  async created(): Promise<void> {
    await this.getMerkList(true);
  }

  async getMerkList(firstLoad = false): Promise<void> {
    try {
      this.merkOption.fetching = true;
      const param: RequestQueryParamsModel = {
        search: "",
      };
      if (this.merkOption.search) {
        param.search = this.merkOption.search;
      }
      const res = await assetsServices.listMerk(param);
      this.merkOption.data = res;
      this.$emit("on-getListMerk", res);
      if (firstLoad) {
        this.merkOption.initialData = res;
      }
      this.merkOption.fetching = false;
    } catch (error) {
      this.merkOption.fetching = false;
    }
  }

  searchMerk(value: string): void {
    this.merkOption.search = value;
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.resetOptionList();
  }

  resetOptionList(): void {
    this.merkOption.data = this.merkOption.initialData;
  }

  filterOption(input, option): boolean {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  addNew(): void {
    if (this.iconType === "loading") return;
    const payload = {
      name: this.merkOption.search,
    };
    this.iconType = "loading";
    productService
      .addMerk(payload)
      .then(() => {
        this.merkOption.search = "";
        this.getMerkList();
      })
      .finally(() => (this.iconType = "plus"));
  }
}
